import axiosInstance from "@/lib/axios/axiosInstance";
import { Search } from "lucide-react";
import React from "react";

interface SearchFormProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  translations: any;
  formClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  autoFocus?: boolean;
}

export function SearchForm({
  searchQuery,
  setSearchQuery,
  translations,
  formClassName,
  inputClassName,
  buttonClassName,
  autoFocus = false,
}: SearchFormProps) {
  return (
    <form onSubmit={(e) => handleSearchSubmit(e, searchQuery, translations)} className={formClassName}>
      <input
        type="search"
        value={searchQuery}
        // disabled={true}
        // readOnly={true}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={translations.searchPlaceHolder}
        className={inputClassName}
        aria-label="Search"
        autoFocus={autoFocus}
      />
      <button type="submit" className={buttonClassName} aria-label="Search">
        <Search size={18} />
      </button>
    </form>
  );
}

export const handleSearchSubmit = async (e: React.FormEvent, searchQuery: string, translations: any) => {
  e.preventDefault();
  
  try {
    // Quick api request for sensitive search query
    await axiosInstance.head(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/post/search?searchQuery=${encodeURIComponent(searchQuery)}`
    );    
    window.location.href = translations.path + "post/search" + "?search=" + encodeURIComponent(searchQuery);
  } catch (error: any) {
    if (error.response?.status === 451) {
      window.alert(translations.sensitivePrompt);
      return;
    }
    // For other errors, still redirect to search page
    window.location.href = translations.path + "post/search" + "?search=" + encodeURIComponent(searchQuery);
  }
};
